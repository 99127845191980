import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2685 1530 c184 -26 394 -107 560 -215 117 -76 292 -251 370 -368 240
-365 298 -793 161 -1194 -110 -321 -364 -612 -673 -767 -201 -102 -375 -141
-618 -140 -147 1 -185 4 -280 27 -252 60 -460 173 -638 346 -201 195 -332 429
-389 696 -29 135 -35 356 -14 486 46 276 182 543 374 733 230 228 462 344 802
399 51 9 277 6 345 -3z m-785 -3025 c0 -92 -1 -95 -22 -95 -22 0 -23 3 -23 95
0 92 1 95 23 95 21 0 22 -3 22 -95z m77 89 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m400 -36 c16 -26 29 -64 33 -95 6 -50 5 -53 -17 -53 -20
0 -23 5 -23 43 0 27 -10 60 -29 95 -27 48 -28 52 -10 52 11 0 30 -17 46 -42z
m96 35 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m129 -5 c-22
-22 -13 -46 28 -69 51 -29 63 -64 30 -91 -12 -10 -30 -17 -39 -17 -14 1 -13 2
2 6 31 8 21 49 -18 73 -57 35 -72 79 -32 98 30 14 44 15 29 0z m213 -69 c0
-69 3 -83 20 -96 19 -15 19 -15 -5 -8 -40 12 -45 22 -50 105 -5 75 -4 80 15
80 18 0 20 -7 20 -81z m247 -10 l-2 -90 27 3 c23 2 29 9 31 33 3 18 -2 33 -13
41 -10 8 -12 14 -6 19 15 9 14 58 -1 73 -15 15 -6 15 27 0 31 -14 34 -53 4
-69 l-21 -11 23 -12 c61 -30 19 -86 -64 -86 l-48 0 2 95 c1 90 3 95 23 95 20
0 21 -4 18 -91z m-861 -9 c34 -83 34 -103 2 -82 -23 14 -65 122 -59 147 4 14
10 25 14 25 4 0 23 -41 43 -90z m466 68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-684 -156 c-31 -8 -82 70 -69 104 5 13 16 2 44 -42 28 -43
35 -60 25 -62z m580 33 c4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z
m-466 -31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-36 -138
c-13 -15 2 -36 40 -56 25 -14 24 -46 0 -60 -28 -15 -35 -12 -20 6 12 14 8 20
-24 46 -39 32 -45 46 -25 66 18 18 44 16 29 -2z m59 -7 c0 -5 -4 -9 -10 -9 -5
0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m541 8 c-1 -12 -15 -9 -19
4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-446 -64 c-2 -21 1 -43 7 -50 16 -19 -7
-17 -26 3 -27 27 -15 77 21 84 0 0 -1 -17 -2 -37z m43 25 c16 -16 15 -38 -2
-38 -7 0 -16 11 -18 25 -6 27 1 32 20 13z m106 6 c11 -4 16 -19 16 -50 0 -36
-3 -44 -18 -44 -15 0 -18 9 -18 50 0 28 1 50 2 50 1 0 9 -3 18 -6z m56 -9 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m99
-14 c22 -30 28 -61 12 -61 -5 0 -21 20 -34 45 -30 52 -12 64 22 16z m151 -21
c0 -38 -4 -50 -15 -50 -11 0 -15 12 -15 50 0 38 4 50 15 50 11 0 15 -12 15
-50z m103 15 c-3 -21 -1 -41 7 -50 17 -20 -4 -20 -24 1 -27 27 -15 78 21 84 1
0 -1 -16 -4 -35z m201 12 c8 -14 8 -20 -4 -27 -12 -7 -16 -4 -18 15 -2 14 -10
25 -18 25 -18 0 -19 -61 -2 -78 9 -9 9 -12 -3 -12 -23 0 -39 21 -39 52 0 45
60 64 84 25z m-144 3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-803 -72 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M2305 3950 c-541 -83 -977 -517 -1064 -1060 -16 -95 -13 -301 4 -410
67 -412 341 -769 729 -950 45 -22 138 -54 206 -72 121 -31 132 -33 320 -33
189 0 199 1 315 33 496 134 859 531 941 1030 19 113 14 341 -10 457 -66 318
-266 620 -529 796 -142 95 -262 148 -432 190 -92 24 -378 34 -480 19z m384
-305 c309 -65 573 -279 697 -564 80 -186 103 -393 64 -581 -78 -377 -350 -658
-730 -756 -112 -28 -344 -27 -460 4 -197 51 -406 189 -527 347 -68 88 -146
246 -173 350 -31 117 -38 316 -16 425 43 211 121 357 270 505 145 145 297 227
501 270 85 18 289 18 374 0z"/>
<path d="M2331 3580 c-174 -37 -326 -117 -455 -239 -116 -112 -193 -237 -244
-395 -23 -72 -26 -98 -26 -241 -1 -135 2 -172 21 -240 46 -169 123 -299 248
-420 161 -156 344 -236 576 -251 421 -29 808 251 921 666 30 112 32 315 5 433
-75 319 -299 559 -617 662 -74 24 -104 28 -235 31 -82 2 -170 -1 -194 -6z m77
-407 c-2 -21 -10 -29 -30 -33 -71 -13 -81 -49 -76 -275 l3 -150 52 -3 53 -3
66 103 c119 185 172 263 198 290 14 15 26 30 26 33 0 3 -18 5 -40 5 -38 0 -40
2 -40 30 l0 30 169 0 168 0 -5 -25 c-3 -17 -13 -27 -31 -31 -88 -19 -131 -37
-170 -70 -40 -35 -120 -147 -215 -302 l-38 -64 39 -36 c22 -21 95 -111 164
-202 155 -207 212 -265 273 -282 38 -10 46 -16 46 -35 0 -23 -2 -23 -107 -23
l-107 0 -50 53 c-28 28 -105 124 -170 212 -66 88 -140 181 -164 207 -38 41
-50 48 -83 48 l-39 0 0 -224 0 -225 53 -3 c51 -3 52 -4 55 -35 l3 -33 -175 0
-176 0 0 23 c0 19 8 26 38 35 68 21 66 8 70 500 l4 442 -56 0 -56 0 0 35 0 35
176 0 175 0 -3 -27z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
